/* eslint-disable no-underscore-dangle */
import onLoadAsync from 'shared/on_load_async.js';

function trackers() {
  function hydrateTrackersWith(data) {
    if (window.Klaro?.getManager().consents.matomo) {
      window._mtm.push(data);
    }
  }

  // Clic CTA souscription header
  document.querySelectorAll('[data-gtm-event=btn-header-souscription]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaSouscriptionHeader',
        getCategory: 'Navigation',
        getAction: 'CTA Header',
        getLabel: 'Souscription',
      });
    });
  });

  // Clic CTA espace client header
  document.querySelectorAll('[data-gtm-event=btn-header-espace-client]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaEspaceClientHeader',
        getCategory: 'Navigation',
        getAction: 'CTA Header',
        getLabel: 'Espace Client',
      });
    });
  });

  // Clic CTA espace sociétaire client header
  document.querySelectorAll('[data-gtm-event=btn-header-societaire]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaEspaceSocietaireHeader',
        getCategory: 'Navigation',
        getAction: 'CTA Header',
        getLabel: 'Espace Sociétaire',
      });
    });
  });

  // Clic sur les boutons sociaux
  document.querySelectorAll('[data-gtm-event=social-network-page-links] a').forEach((link) => {
    link.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'clicSocialPage',
        getCategory: 'Navigation',
        getAction: 'Bouton Réseau Social',
        getLabel: link.dataset.networkName,
      });
    });
  });

  // Clic sur les CTA des blocs
  document.querySelectorAll('.bloc a[data-prismic-document-category]').forEach((link) => {
    const category = link.dataset.prismicDocumentCategory;
    if (!category) {
      return;
    }
    link.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaBloc',
        getCategory: 'Navigation',
        getAction: 'CTA Bloc',
        getLabel: category,
      });
    });
  });

  // Clic CTA souscription home
  document.querySelector('[data-gtm-event=btn-bloc-page-accueil-souscription]')?.addEventListener('click', () => {
    hydrateTrackersWith({
      event: 'genericEvent',
      eventType: 'ctaSouscriptionHome',
      getCategory: 'Navigation',
      getAction: 'CTA Home',
      getLabel: 'Souscription',
    });
  });

  // Clic sur le partage via les réseaux sociaux
  document.querySelectorAll('[data-gtm-event=social-network-share-links] a').forEach((link) => {
    link.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'clicSocialShare',
        getCategory: 'Engagement',
        getAction: 'Partage Réseau Social',
        getLabel: link.dataset.networkName,
      });
    });
  });

  // Clic question sur la page de contact
  // Note: we are adding a listener on document because the question links can be added dynamically
  document.addEventListener('click', (event) => {
    const link = event.target.closest('a[data-gtm-event=contact-form-question]');
    if (link) {
      const questionText = link.textContent;
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'clicQuestionContact',
        getCategory: 'Navigation',
        getAction: 'Question Page Contact',
        getLabel: questionText,
      });
    }
  });

  // Envoi du formulaire de contact
  document.addEventListener('submit', (event) => {
    const contactForm = document.querySelector('form#contact_form');
    if (!contactForm || event.target !== contactForm) {
      return;
    }

    const selects = contactForm.querySelectorAll('select.step-select');
    const concatenatedStepLabels = Array.from(selects).map((select) => select.options[select.selectedIndex].innerHTML).join(' | ');

    hydrateTrackersWith({
      event: 'genericEvent',
      eventType: 'contactFormSent',
      getCategory: 'Engagement',
      getAction: 'Formulaire contact',
      getLabel: concatenatedStepLabels,
    });
  });
}

onLoadAsync(() => {
  if (window.initTrackers) window.initTrackers(trackers);
});

/* eslint-disable import/no-dynamic-require */
/* eslint no-console:0 */
import '../../assets/stylesheets/application.sass';
import 'shared/components/accordion.js';
import 'shared/components/modal.js';
import 'shared/components/burger_menu.js';
import 'shared/slider.js';
import '../carte_simple_el.js';
import '../filtre_recherche.js';
import '../tracking_events.js';
import '../homepage.js';
import '../navigation_mobile.js';
import '../navigation_desktop.js';

const images = import.meta.webpackContext('../../assets/images', { recursive: true });
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);
